<template>
    <div class="logs">
        <div class="nav-box">
            <div class="nav-item" :class="navIndex == item.index ? 'active' : ''" v-for="(item, index) in navList"
                :key="index" @click="queryType(item.index, item.ywType)">{{ item.title }}</div>
        </div>

        <div class="list-box" @scroll="scroll">
            <div class="scroll">
                <div class="scroll-item" v-for="(val, j) in list" :key="j" @click="jumpAffiche(val)">
                    <div class="name">{{ val.ywTitle }}</div>
                    <div class="date-box">
                        <div class="icon">
                            <img style="width: 100%; height: 100%;" src="@/assets/images/time.png" alt="">
                        </div>
                        <div class="time">{{ val.ywStartTime }} - {{ val.ywEndTime }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="!emptyState">
            <div class="technology" v-show="!loading">该系统由东莞市海基智远人力资源服务有限公司提供技术支持</div>
            <div class="loading" v-show="loading">
                <div class="loading-icon">
                    <img style="width: 100%; height: 100%;" src="@/assets/images/loading.png" alt="">
                </div>
            </div>
        </div>
        <div class="empty" v-show="emptyState">
            暂无更多...
        </div>
    </div>
</template>

<script>
import common from "@/api/common.js"
// import noMultipleClicks from "@/utils/noMultipleClicks.js"
export default {
    data() {
        return {
            // 必写
            noClick: true,
            navIndex: 1,
            pageNum: 1,
            ywType: '招聘公告',
            navList: [
                {
                    title: "考试报名",
                    index: 1,
                    ywType: "招聘公告"
                },
                {
                    title: "准考证打印通知",
                    index: 2,
                    ywType: "准考证公告"
                },
                {
                    title: "成绩查询通知",
                    index: 3,
                    ywType: "成绩查询公告"


                },
                {
                    title: "延长报名通知",
                    index: 4,
                    ywType: "延迟考试公告"
                },
            ],

            list: [],
            loading: true,
            // 最大页数
            total: null,
            emptyState: false,
        }
    },

    created() {
        this.getList()
    },

    mounted() {
    },

    methods: {
        // 查看公告
        jumpAffiche(val){
            console.log(val);
            window.location.href ='/afficheDetails?id='+val.ywId
            // this.$router.push({path:'/afficheDetails',query:{id:val.ywId,date:new Date()}})
        },
        // 监听滚动触底判断
        scroll(e) {
            // 滚动内容高度
            let height = document.querySelector(".scroll").clientHeight;
            // 触底的方法等于 = 滚动条距离顶部+滚动条的高度=滚动条的高度 
            // 视口高度
            let innerHeight = window.innerHeight;
            var scrollTop = e.target.scrollTop + (innerHeight - 100);
            if (height <= scrollTop) {
                // 节流
                if (!this.isLoading) {
                    this.isLoading = true;
                    setTimeout(() => {
                        // 发送请求
                        this.getList()
                        this.isLoading = false
                    }, 500)
                }
            }
        },
        // 差选类型
        queryType(val, ywType) {
            this.total = null;
            this.navIndex = val;
            this.ywType = ywType;
            this.pageNum = 1;
            this.list = [];
            this.getList();
            this.clearScroll()
        },

        // 获取数据
        getList() {
            if (this.total != null && this.total < this.pageNum) {
                this.emptyState = true;
                setTimeout(() => {
                    this.emptyState = false;
                }, 2000)
                return;
            }
            this.loading = true;
            // 获取准备考证打印
            common.getTweePagingtList(this.pageNum, this.ywType, "", "").then(res => {
                if (res.code == 200) {
                    this.total = Math.ceil(res.total / 10)
                    console.log(this.total, "ddd");
                    this.list = [...this.list, ...res.rows];
                    this.pageNum++;
                    this.throttle = false;
                    console.log(this.pageNum);
                }
                this.loading = false;
            })


        },

        // 监听滚动事件
        bindScroll() {
            window.addEventListener('scroll', this.scroll)
        },
        // 清除scroll事件
        clearScroll() {
            window.removeEventListener('scroll', this.scroll)
        }
    },
    // 组件销毁的生命周期
    destroyed() {
        this.clearScroll()
    }
}
</script>

<style scoped>
body,
html {
    overflow: hidden;
}

.logs {
    height: 100vh;
    background: #f5f5f5;
}

.nav-box {
    width: 100vw;
    background: #ffffff;
    display: flex;
    /* position: fixed;
    top: 0px;
    left: 0px; */
}

.nav-item {
    flex: 1;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 50px;
    color: #999999;
    text-align: center;
}

.nav-item.active {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #E01811;
    position: relative;
}

.active::after {
    content: '';
    height: 2px;
    width: 16px;
    background: #E01811;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}

.list-box {
    height: calc(100vh - 100px);
    overflow: hidden;
    overflow-y: auto;
    padding: 16px;
    box-sizing: border-box;
}


.technology {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 50px;
    color: #999999;
    text-align: center;
}

.empty {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 50px;
    color: #999999;
    text-align: center;
}

.loading {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;


}

.loading-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    animation: rotate 2.2s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotateZ(360deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}


.scroll-item {
    padding: 16px;
    border-radius: 4px;
    background: #ffffff;
    margin-bottom: 16px;
}

.name {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 50px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.date-box {
    display: flex;
    align-items: center;
}

.icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.time {
    flex: 1;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
}
</style>